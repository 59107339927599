import type { Mutable } from '@studio/utils/types';

import type {
  EligibleExperience,
  Experience,
  ExperienceConfig,
  SecondaryExperienceKey,
  StudioExperience,
} from './types';

export type ExperienceEligibilityChecker = <SomeExperience extends Experience>(
  experience: SomeExperience,
) => experience is SomeExperience & EligibleExperience;

export function createExperienceEligibilityChecker(
  experienceConfig: ExperienceConfig,
  secondaryExperienceKeys: readonly SecondaryExperienceKey[],
): ExperienceEligibilityChecker {
  return function checkExperienceEligibility<SomeExperience extends Experience>(
    experience: SomeExperience,
  ): experience is SomeExperience & EligibleExperience {
    // Disallow Studio experiences that are not defined in the config
    if (
      experience.studioExperience != null &&
      experienceConfig[experience.studioExperience] == null
    ) {
      return false;
    }

    // Disallow secondary keys that are not present in secondaryExperienceKeys
    for (const currentExperienceKeyRaw in experience) {
      if (Object.hasOwn(experience, currentExperienceKeyRaw)) {
        const currentExperienceKey =
          currentExperienceKeyRaw as keyof Experience;

        if (
          currentExperienceKey !== 'studioExperience' &&
          experience[currentExperienceKey] != null &&
          !secondaryExperienceKeys.includes(currentExperienceKey)
        ) {
          return false;
        }
      }
    }

    // Disallow secondary experience values that are not present in the config
    for (const secondaryExperienceKey of secondaryExperienceKeys) {
      // Disallow null studioExperience if we have at least one secondary
      if (experience.studioExperience == null) {
        return false;
      }

      const experienceValue = experience[secondaryExperienceKey];
      if (experienceValue == null) {
        return false;
      }

      if (
        // prettier-ignore
        !experienceConfig
          ?.[experience.studioExperience]
          ?.[secondaryExperienceKey]
          ?.includes(experienceValue as never)
      ) {
        return false;
      }
    }

    return true;
  };
}

export function* getAllEligibleExperiences(
  experienceConfig: ExperienceConfig,
  secondaryExperienceKeys: readonly SecondaryExperienceKey[],
): Generator<EligibleExperience> {
  for (const studioExperienceRaw in experienceConfig) {
    if (Object.hasOwn(experienceConfig, studioExperienceRaw)) {
      const studioExperience = studioExperienceRaw as StudioExperience;
      yield* getMoreEligibleExperiences(
        { studioExperience } as EligibleExperience,
        experienceConfig[studioExperience],
        secondaryExperienceKeys,
      );
    }
  }
}

/**
 * The eligble experiences that can be formed by extending the
 * current experience by replacing blank or invalid values.
 */
export function getEligibleExperiences(
  currentExperience: Experience | null,
  experienceConfig: ExperienceConfig,
  secondaryExperienceKeys: readonly SecondaryExperienceKey[],
): IteratorObject<EligibleExperience> {
  if (
    currentExperience?.studioExperience == null ||
    experienceConfig[currentExperience.studioExperience] == null
  ) {
    return getAllEligibleExperiences(experienceConfig, secondaryExperienceKeys);
  }

  const nextExperience: Mutable<Experience> = {
    studioExperience: currentExperience.studioExperience,
  };

  const presentSecondaryExperienceKeys: SecondaryExperienceKey[] = [];
  const missingSecondaryExperienceKeys: SecondaryExperienceKey[] = [];

  for (const secondaryExperienceKey of secondaryExperienceKeys) {
    const experienceValue = currentExperience[secondaryExperienceKey];
    if (
      experienceValue == null ||
      !experienceConfig?.[currentExperience.studioExperience]?.[
        secondaryExperienceKey
      ]?.includes(experienceValue as never)
    ) {
      missingSecondaryExperienceKeys.push(secondaryExperienceKey);
    } else {
      presentSecondaryExperienceKeys.push(secondaryExperienceKey);
      nextExperience[secondaryExperienceKey] = experienceValue as never;
    }
  }

  const isEligibleExperience = createExperienceEligibilityChecker(
    experienceConfig,
    presentSecondaryExperienceKeys,
  );

  if (!isEligibleExperience(nextExperience)) {
    return Iterator.from([]);
  }

  return getMoreEligibleExperiences(
    nextExperience,
    experienceConfig[currentExperience.studioExperience],
    missingSecondaryExperienceKeys,
  );
}

function* getMoreEligibleExperiences(
  experience: EligibleExperience,
  eligibleExperienceValues: ExperienceConfig[StudioExperience] | undefined,
  [
    secondaryExperienceKey,
    ...remainingSecondaryExperienceKeys
  ]: readonly SecondaryExperienceKey[],
): Generator<EligibleExperience> {
  if (secondaryExperienceKey === undefined) {
    yield experience;
  } else {
    const secondaryExperienceValues =
      eligibleExperienceValues?.[secondaryExperienceKey];

    if (secondaryExperienceValues !== undefined) {
      for (const secondaryExperienceValue of secondaryExperienceValues) {
        yield* getMoreEligibleExperiences(
          {
            ...experience,
            [secondaryExperienceKey]: secondaryExperienceValue,
          },
          eligibleExperienceValues,
          remainingSecondaryExperienceKeys,
        );
      }
    }
  }
}
