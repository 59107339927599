import type { Preferences } from '@studio/api/custom-attributes';
import type { Inlined } from '@studio/api/json-api';
import type { Settings } from '@studio/api/settings';
import type { CurrentUser, UmsUiConfig } from '@studio/api/users';
import type { EnvironmentConfig } from '@studio/utils/fromEnvironment';

import type { PermissionGroups } from '../../utils/permission';
import { LoginMode, type SessionState, SessionStateStatus } from './reducer';

function selectLoggedInSessionState(sessionState: SessionState) {
  if (sessionState.status !== SessionStateStatus.LoggedIn) {
    return undefined;
  }
  return sessionState;
}

function selectLoggedInSessionStateOrThrow(sessionState: SessionState) {
  if (sessionState.status !== SessionStateStatus.LoggedIn) {
    throw new Error('You must be logged-in to access logged-in state');
  }
  return sessionState;
}

export function selectUser(
  sessionState: SessionState,
): CurrentUser | undefined {
  return selectLoggedInSessionState(sessionState)?.user;
}

export function selectLoginMode(sessionState: SessionState): LoginMode {
  return selectLoggedInSessionState(sessionState)?.mode ?? LoginMode.Modern;
}

export function selectRealm(sessionState: SessionState): string | undefined {
  return selectUser(sessionState)?.realm;
}

export function selectSettings(sessionState: SessionState): Settings {
  return selectLoggedInSessionStateOrThrow(sessionState).settings;
}

export function selectUmsUiConfig(
  sessionState: SessionState,
): Inlined<UmsUiConfig> {
  return selectSettings(sessionState).umsUiConfig;
}

export function selectRealmTimeZone(sessionState: SessionState): string {
  return selectSettings(sessionState).studioConfig.locale.timeZone;
}

export function selectPreferences(sessionState: SessionState): Preferences {
  return selectLoggedInSessionStateOrThrow(sessionState).preferences;
}

export function selectPermissionGroups(
  sessionState: SessionState,
): PermissionGroups | undefined {
  return (
    selectLoggedInSessionState(sessionState)?.permissionGroups ?? undefined
  );
}

export function selectEnvironment(
  sessionState: SessionState,
): EnvironmentConfig | undefined {
  return (
    selectLoggedInSessionState(sessionState)?.settings?.environment ?? undefined
  );
}
