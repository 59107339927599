import {
  type AccessContext,
  type AccessFn,
  isRole,
  Role,
} from '@studio/api/access';
import { getPublishingSiteRole } from '@studio/api/identity';
import type { PseudoPartial } from '@studio/utils/types';

import type { ExperienceRoleResolver } from './roles';
import type { AuthorizedExperience, Experience } from './types';
import { retainSet } from './utils';

export type ExperienceAuthorizationChecker = <
  SomeExperience extends Experience,
>(
  experience: SomeExperience,
) => experience is SomeExperience & AuthorizedExperience;

export function createExperienceAuthorizationChecker(
  access: AccessFn,
  accesContextWithoutExperience: AccessContextWithoutExperience,
  resolveExperienceRoles: ExperienceRoleResolver,
): ExperienceAuthorizationChecker {
  return function checkExperienceAuthorization<
    SomeExperience extends Experience,
  >(
    experience: SomeExperience,
  ): experience is SomeExperience & AuthorizedExperience {
    const experienceRoles = resolveExperienceRoles(experience);

    // Special publishing site access logic
    if (
      experience.publishingSite != null &&
      !experienceRoles.has(Role.Admin) &&
      !experienceRoles.has(getPublishingSiteRole(experience.publishingSite))
    ) {
      return false;
    }

    // Remove unknown roles
    retainSet(experienceRoles, isRole);

    const accessContext = getExperienceAccessContext(
      accesContextWithoutExperience,
      experience,
      experienceRoles,
    );

    return access(accessContext);
  };
}

function getPartialExperienceAccessContext(
  experience: Experience,
  roles: ReadonlySet<Role>,
) {
  return {
    roles,
    studioExperience: experience.studioExperience,
    subdivisionTenant: experience.subdivisionTenant ?? undefined,
    subdivisionMarket: experience.subdivisionMarket ?? undefined,
    publishingSite: experience.publishingSite ?? undefined,
  } satisfies Partial<AccessContext>;
}

/** The subset of the access context that is not derived from the experience */
export type AccessContextWithoutExperience = Omit<
  PseudoPartial<AccessContext>,
  keyof ReturnType<typeof getPartialExperienceAccessContext>
>;

export function getExperienceAccessContext(
  accessContextWithoutExperience: AccessContextWithoutExperience,
  experience: Experience,
  roles: ReadonlySet<Role>,
): PseudoPartial<AccessContext> {
  return {
    ...accessContextWithoutExperience,
    ...getPartialExperienceAccessContext(experience, roles),
  };
}
