import moment from 'moment';

import { isAbortError } from '@studio/utils/abortError';
import acquireLock from '@studio/utils/acquireLock';
import type { PublishingSite } from '@studio/utils/experience';
import { rebase } from '@studio/utils/patch';
import type { RequestContext } from '@studio/utils/requestContext';
import safeJsonParse from '@studio/utils/safeJsonParse';
import setByPath from '@studio/utils/setByPath';

import { Role } from '../access';
import { SEARCH_DEFAULT_PAGE, SEARCH_DEFAULT_PAGE_SIZE } from '../constants';
import { ApiError, HttpError } from '../errors';
import {
  type AnyResource,
  type DataDocument,
  type Inlined,
  inlineDocument,
  isDataDocumentOf,
  uninlineResource,
} from '../json-api';
import { apiURL, fetchWrapper } from '../utils';
import {
  type BlockedEmail,
  type BrazeResponse,
  type BrazeUser,
  type CommunicationHistory,
  type CurrentUserLegacy,
  type DefederateUserPayload,
  type Download,
  type Downloads,
  type EmailTemplatePreview,
  type FreeTextSearchUser,
  type GeoOverride,
  type GetUsersResponse,
  type GetUsersResponseWithRequestId,
  isMfaSetupResource,
  isMfaStatusResource,
  type LogLevel,
  type MfaStatus,
  MfaVerifyStatus,
  type Paginated,
  type Partner,
  type PrivilegedIP,
  type RawUser,
  type ResetAuthyResponse,
  type StudioConfig,
  type TokenResource,
  type TokenResourceAttributes,
  type UmsUiConfig,
  type UpdateUserResponse,
  type UserDetails,
  type UserDevice,
  type UserProfile,
  type UserResource,
  UserType,
  type verifyMfaCodeResponse,
} from './types';

export * from './types';

const MFA_VERIFY_LOCK_NAME = 'MFA_VERIFY';

const isMfaSetupDataDocument = isDataDocumentOf(isMfaSetupResource);

const isMfaStatusDataDocument = isDataDocumentOf(isMfaStatusResource);

export interface MfaSetup {
  readonly qrCode: string | null;
}

interface ProviderCredentials {
  studio_okta: {
    oktaId: string;
  };
}

export interface ChangePasswordConfig {
  currentPassword: string;
  password: string;
}

export interface ResetAuthTokenConfig {
  realm: string;
}

export interface LoginConfig {
  authToken: string;
  username: string;
  password: string;
}

export interface LogoutConfig {
  realm: string | null;
}

export interface ResetPasswordConfig {
  authToken?: string;
  username: string;
  emailTemplate: 'sonic_portal_reset_password' | 'reset_password';
}

export interface FinishResettingPasswordConfig {
  authToken: string;
  password: string;
  passwordResetToken: string;
}

export interface FreeTextUsersSearchQuery {
  readonly query?: string;
  readonly page: number;
  readonly pageSize: number;
  readonly sortKey?: string;
  readonly sortOrder: 'asc' | 'desc';
  readonly registeredInLocationTerritories: readonly string[];
  readonly roles: readonly Role[];
  readonly logLevels: readonly LogLevel[];
  readonly userIds?: readonly string[];
}

export interface UsersDetailedSearchQuery {
  readonly firstName: string | undefined;
  readonly lastName: string | undefined;
  readonly username: string | undefined;
  readonly page: number;
  readonly pageSize: number;
}

interface UserBulkSaveRequest {
  updatedProfiles?: readonly { data: Partial<UserProfile> }[];
  deletedProfiles?: readonly string[];
  newProfiles?: readonly { data: UserProfile }[];
  user?: { data: UserResource };
  details?: { data: UserDetails };
}

interface UserBulkSaveResponse {
  createdProfileIds: string[];
  profiles: DataDocument<UserProfile[]>;
  details: DataDocument<UserDetails>;
  user: DataDocument<UserResource>;
}

export interface FeatureFlags {
  readonly [key: string]: boolean;
}

export interface UpdateUserRequest {
  userId: string;
  details?: Inlined<UserDetails>;
  user?: Inlined<UserResource>;
  featureFlags?: FeatureFlags;
}

export interface UpdateUserBlockedEmailRequest {
  userId: string;
  username: string;
}

export interface BlockedEmailSearchQuery {
  readonly page?: number;
  readonly pageSize?: number;
  readonly query?: string;
}

export interface CommunicationHistoryQuery {
  readonly page?: number;
  readonly pageSize?: number;
  readonly userId: string;
}

export interface PPVTransactionsQuery {
  readonly userId: string;
  readonly page?: number;
  readonly pageSize?: number;
}

export interface BrazeQuery {
  readonly email: string;
  readonly userId: string;
}

export interface TemplatePreviewQuery {
  readonly templateName: string;
  readonly templateVersion: string;
  readonly realm: string;
  readonly tokens: Readonly<Record<string, string>>;
}

function getCurrentUserLegacyFromResource(
  userResource: UserResource,
): CurrentUserLegacy {
  const { id, attributes } = userResource;

  if (id == null || attributes == null) {
    throw new TypeError('Invalid user resource');
  }

  const {
    username,
    realm,
    roles = [],
    anonymous,
    selectedAuthProvider: authProvider,
    authProviders = [],
    allowedPublishingSites = [],
    selectedPublishingSite,
  } = attributes;

  return {
    type: UserType.Legacy,
    id,
    username,
    realm,
    roles,
    legacyRoles: roles,
    groups: [],
    anonymous,
    authProvider,
    authProviders,
    allowedPublishingSites,
    selectedPublishingSite,
  };
}

function getUserDataLockName(userId: string | null) {
  return `user:${userId ?? 'unknown'}`;
}

// Backend  only allows certain attributes to be sent with PATCH 🤷‍♀️
function createAllowedUserAttributesUpdate(
  currentUserRoles: readonly Role[],
  {
    firstName,
    lastName,
    deleted,
    authyId,
    providers,
    failedLoginAttempts,
    mfaEnabled,
    roles,
    allowedPublishingSites,
    logLevel,
    registeredInLocationTerritory,
    verifiedHomeTerritoriesOverride,
    countryCodeOverride,
    testUser,
    testUserDescription,
    testUserExpiration,
    migratedFromTerritory,
    migrationStatus,
  }: NonNullable<UserResource['attributes']>,
): NonNullable<Partial<UserResource['attributes']>> {
  const isUserAdmin = currentUserRoles?.includes(Role.UsersAdmin);
  const isSuperAdmin = currentUserRoles?.includes(Role.Admin);

  const attributes = {
    firstName,
    lastName,
    deleted,
    authyId,
    username: providers?.username_password?.username,
    password:
      testUser && providers?.username_password?.password !== ''
        ? providers?.username_password?.password
        : undefined,
    failedLoginAttempts,
    mfaEnabled,
    roles,
    allowedPublishingSites,
    logLevel,
    registeredInLocationTerritory: registeredInLocationTerritory ?? '',
    verifiedHomeTerritoriesOverride:
      typeof verifiedHomeTerritoriesOverride === 'string'
        ? [verifiedHomeTerritoriesOverride]
        : verifiedHomeTerritoriesOverride,
    countryCodeOverride:
      countryCodeOverride != null ? countryCodeOverride.toLowerCase() : null,
    testUser,
    testUserDescription: testUser ? testUserDescription : null,
    testUserExpiration: testUser ? testUserExpiration : null,
    migratedFromTerritory,
    migrationStatus,
  };

  if (isSuperAdmin) {
    return attributes;
  }

  if (isUserAdmin) {
    const {
      roles: _roles,
      mfaEnabled: _mfaEnabled,
      ...filteredAttributes
    } = attributes;
    return filteredAttributes;
  }

  // Fields allowed for customer service users
  return {
    firstName: attributes.firstName,
    lastName: attributes.lastName,
    username: attributes.username,
    deleted: attributes.deleted,
  };
}

// Backend  only allows certain attributes to be sent with PATCH 🤷‍♀️
function createAllowedUserDetailsAttributesUpdate({
  addressLine1,
  addressLine2,
  addressLine3,
  postalCode,
  city,
  state,
  phoneNumber,
  mobileNumber,
}: NonNullable<UserDetails['attributes']>) {
  return {
    addressLine1,
    addressLine2,
    addressLine3,
    postalCode,
    city,
    state,
    phoneNumber,
    mobileNumber,
  };
}

const usersApi = {
  // Hacky AF, but "works"
  async isEnabled(context: RequestContext): Promise<boolean> {
    try {
      const url = apiURL`/admin/users/studioConfig`;
      await fetchWrapper(url, {
        ...context,
        credentials: 'omit',
        skipRetries: true,
        signal: AbortSignal.any([AbortSignal.timeout(10_000), context.signal]),
      });
      return true;
    } catch (error) {
      if (isAbortError(error, context.signal)) {
        throw error;
      }

      if (error instanceof HttpError) {
        if (error.status === 503) {
          // The service is not up
          return false;
        }

        // The request failed, but it's likely to be working
        return true;
      }

      // Unknown error, treat it as not working
      return false;
    }
  },

  /** Resets the current token to an anonymous user */
  async resetAuthToken(
    context: RequestContext,
    { realm }: ResetAuthTokenConfig,
  ): Promise<string> {
    const url = apiURL`/token`;
    url.searchParams.set('realm', realm);
    const response = await fetchWrapper(url, context);

    const document: DataDocument<TokenResource> = await response.json();

    const token = document.data?.attributes?.token;

    if (token === undefined) {
      throw new TypeError(`Unexpected data: ${JSON.stringify(document)}`);
    }

    return token;
  },

  async login(
    context: RequestContext,
    { username, password, authToken }: LoginConfig,
  ): Promise<TokenResourceAttributes> {
    const url = apiURL`/admin/login`;

    const response = await fetchWrapper(url, {
      ...context,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        credentials: {
          username,
          password,
        },
      }),
      // This is only ever made in logged-out mode, so bypass the regular 401 handling
      skipInterceptors: true,
    });

    const document: DataDocument<TokenResource> = await response.json();

    if (document.data.attributes === undefined) {
      throw new TypeError(`Unexpected data: ${JSON.stringify(document)}`);
    }

    return document.data.attributes;
  },

  /**
   * @deprecated Use { @see identityApi.revokeSonicToken } instead
   */
  async logout(
    context: RequestContext,
    { realm }: LogoutConfig,
  ): Promise<void> {
    try {
      const url = apiURL`/logout`;
      await fetchWrapper(url, {
        ...context,
        signal: null,
        method: 'POST',
      });
      context.signal.throwIfAborted();
    } finally {
      // The logout should return a new token for an anonymous user, but it might fail if the token is expired
      // This request will force a new token to be created for an an anunymous user, which overwrites the current `st` token
      await usersApi.resetAuthToken(context, { realm: realm ?? 'bolt' });
    }
  },

  async logoutEverywhere(
    context: RequestContext,
    userIds: readonly string[],
  ): Promise<void> {
    const url = apiURL`/admin/users/logoutEverywhere`;

    await fetchWrapper(url, {
      ...context,
      method: 'POST',
      body: JSON.stringify({ userIds }),
    });
  },

  async addProvider<const Provider extends keyof ProviderCredentials>(
    context: RequestContext,
    provider: Provider,
    credentials: ProviderCredentials[Provider],
  ): Promise<void> {
    const url = apiURL`/users/registration/addProvider`;

    await using _lock = await acquireLock(getUserDataLockName(context.userId), {
      signal: context.signal,
      mode: 'exclusive',
    });

    try {
      await fetchWrapper(url, {
        ...context,
        method: 'POST',
        body: JSON.stringify({
          credentials: {
            provider,
            ...credentials,
          },
        }),
      });
    } catch (error) {
      if (error instanceof HttpError && error.status === 403) {
        throw new Error('This account is already linked to another user.');
      }
      throw error;
    }
  },

  async removeProvider(
    context: RequestContext,
    provider: keyof ProviderCredentials,
  ): Promise<void> {
    const url = apiURL`/users/registration/removeProvider`;

    await using _lock = await acquireLock(getUserDataLockName(context.userId), {
      signal: context.signal,
      mode: 'exclusive',
    });

    await fetchWrapper(url, {
      ...context,
      method: 'POST',
      body: JSON.stringify({
        provider,
      }),
    });
  },

  async resetPassword(
    context: RequestContext,
    { username, emailTemplate, authToken }: ResetPasswordConfig,
  ): Promise<void> {
    const url =
      context.environment?.source === 'bolt'
        ? apiURL`/idp/users/authentication/resetPassword`
        : apiURL`/users/registration/resetPassword`;

    const headers = new Headers();
    if (authToken !== undefined) {
      headers.set('Authorization', `Bearer ${authToken}`);
    }

    await fetchWrapper(url, {
      ...context,
      method: 'POST',
      headers,
      body: JSON.stringify({
        username,
        emailTemplate,
      }),
    });
  },

  async finishResettingPassword(
    context: RequestContext,
    { password, passwordResetToken, authToken }: FinishResettingPasswordConfig,
  ): Promise<void> {
    const url = apiURL`/users/registration/changePassword`;

    await fetchWrapper(url, {
      ...context,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        password,
        passwordResetToken,
      }),
    });
  },

  async changeUsername(
    context: RequestContext,
    username: string,
  ): Promise<void> {
    const url = apiURL`/users/registration/changeUsername`;

    await using _lock = await acquireLock(getUserDataLockName(context.userId), {
      signal: context.signal,
      mode: 'exclusive',
    });

    await fetchWrapper(url, {
      ...context,
      method: 'POST',
      body: JSON.stringify({ username }),
    });
  },

  async getCurrentUser(context: RequestContext): Promise<CurrentUserLegacy> {
    const url = apiURL`/users/me`;

    await using _lock = await acquireLock(getUserDataLockName(context.userId), {
      signal: context.signal,
      mode: 'shared',
    });

    const response = await fetchWrapper(url, context);
    const document: DataDocument<UserResource> = await response.json();

    return getCurrentUserLegacyFromResource(document.data);
  },

  /**
   * @deprecated - Only used in Legacy mode
   */
  async updateSelectedPublishingSite(
    context: RequestContext,
    selectedPublishingSite: PublishingSite,
  ): Promise<CurrentUserLegacy> {
    const url = apiURL`/users/me`;

    await using _lock = await acquireLock(getUserDataLockName(context.userId), {
      signal: context.signal,
      mode: 'exclusive',
    });

    const response = await fetchWrapper(url, {
      ...context,
      method: 'PATCH',
      body: JSON.stringify({
        data: {
          type: 'user',
          id: context.userId,
          // The attributes are allowed to be partial for PATCH
          attributes: {
            selectedPublishingSite,
          } satisfies Partial<UserResource['attributes']>,
        },
      }),
    });

    const document: DataDocument<UserResource> = await response.json();

    return getCurrentUserLegacyFromResource(document.data);
  },

  async getUser(
    context: RequestContext,
    userId: string,
  ): Promise<Inlined<UserResource> | null> {
    const url = apiURL`/admin/users/${userId}`;

    await using _lock = await acquireLock(getUserDataLockName(userId), {
      signal: context.signal,
      mode: 'shared',
    });

    try {
      const response = await fetchWrapper(url, context);
      const document: DataDocument<UserResource> = await response.json();

      return inlineDocument(document);
    } catch (error) {
      if (error instanceof HttpError && error.status === 404) {
        return null;
      }

      throw error;
    }
  },

  async getRawUser(context: RequestContext, userId: string): Promise<RawUser> {
    const url = apiURL`/admin/users/${userId}/raw`;

    await using _lock = await acquireLock(getUserDataLockName(userId), {
      signal: context.signal,
      mode: 'shared',
    });

    const response = await fetchWrapper(url, context);

    // It's not really true that this is a FreeTextSearchUser, it may or may not
    // contain several different additional attributes that we usually don't see
    // in a user, but almost. However, we shouldn't change a raw user. It's only
    // for raw displaying purposes.
    const document: RawUser = await response.json();

    return document;
  },

  async getUsers(
    context: RequestContext,
    {
      query,
      page,
      pageSize,
      sortKey,
      sortOrder,
      registeredInLocationTerritories,
      roles,
      logLevels,
      userIds,
    }: FreeTextUsersSearchQuery,
  ): Promise<GetUsersResponseWithRequestId> {
    const url = apiURL`/admin/users/freeTextSearch`;

    const response = await fetchWrapper(url, {
      ...context,
      method: 'POST',
      body: JSON.stringify({
        freeText: query,
        from: page,
        pageSize,
        sortKey,
        sortOrder,
        registeredInLocationTerritories:
          registeredInLocationTerritories?.join(','),
        roles: roles?.join(','),
        logLevels: logLevels?.join(','),
        userIds: userIds?.join(','),
      }),
    });

    const document: {
      data: FreeTextSearchUser[];
      meta: { totalHits: number };
    } = await response.json();

    return { ...document, requestId: response.headers.get('X-Disco-Id') };
  },

  /** @experimental This endpoint is not stable and only for experimental purposes; use getUser instead */
  async getUsersDetailedSearch(
    context: RequestContext,
    { page, pageSize, firstName, lastName, username }: UsersDetailedSearchQuery,
  ): Promise<GetUsersResponse> {
    const url = apiURL`/admin/users/multiSearch`;

    const response = await fetchWrapper(url, {
      ...context,
      method: 'POST',
      body: JSON.stringify({
        from: page,
        pageSize,
        searchBy: {
          firstName,
          lastName,
          username,
        },
      }),
    });

    const document: {
      data: FreeTextSearchUser[];
      meta: { totalHits: number };
    } = await response.json();

    return document;
  },

  async deleteUser(context: RequestContext, userId: string): Promise<void> {
    const url = apiURL`/admin/users/${userId}`;

    await using _lock = await acquireLock(getUserDataLockName(userId), {
      signal: context.signal,
      mode: 'exclusive',
    });

    await fetchWrapper(url, { ...context, method: 'DELETE' });
  },

  async createUser(
    context: RequestContext,
    attributes: Partial<UserResource['attributes']>,
  ): Promise<Inlined<UserResource>> {
    const url = apiURL`/admin/users`;

    const body: DataDocument<AnyResource> = {
      data: {
        type: 'user',
        attributes: {
          ...attributes,
          username: attributes?.username?.toLowerCase(),
        },
      },
    };

    const response = await fetchWrapper(url, {
      ...context,
      method: 'POST',
      body: JSON.stringify(body),
    });

    const dataDocument: DataDocument<UserResource> = await response.json();

    return inlineDocument(dataDocument);
  },

  async updateUser(
    context: RequestContext,
    {
      userId,
      details,
      user,
      featureFlags: { isUserCommunicationEnabled = false } = {},
    }: UpdateUserRequest,
    currentUserRoles: readonly Role[],
  ): Promise<UpdateUserResponse> {
    const url = apiURL`/admin/users/${userId}/bulkSave`;

    await using _lock = await acquireLock(getUserDataLockName(userId), {
      signal: context.signal,
      mode: 'exclusive',
    });

    const filteredUser = {
      id: user!.id,
      type: user!.type,
      attributes: createAllowedUserAttributesUpdate(
        currentUserRoles,
        user!.attributes!,
      ),
    };

    const filteredDetails = {
      // Apparently details id should use user id with "DETAILS:" as prefix 🤷‍♀️
      id: `DETAILS:${userId}`,
      type: details!.type,
      attributes: createAllowedUserDetailsAttributesUpdate(
        details!.attributes!,
      ),
    };

    const outgoingDocument: UserBulkSaveRequest = {
      user: { data: filteredUser as any },
      details: { data: filteredDetails },
    };

    const response = await fetchWrapper(url.href, {
      ...context,
      method: 'PATCH',
      body: JSON.stringify(outgoingDocument),
    });

    const data: UserBulkSaveResponse = await response.json();

    let hasRemovedFromBlockedEmailList = false;
    if (isUserCommunicationEnabled) {
      const blockedEmailData = await usersApi.getBlockedEmail(context, userId);
      const email = blockedEmailData?.attributes?.email;
      const username = user?.attributes?.providers?.username_password?.username;

      if (email !== undefined && username !== undefined && email !== username) {
        await usersApi.removeBlockedEmail(context, userId);
        hasRemovedFromBlockedEmailList = true;
      }
    }

    return {
      userId,
      details: inlineDocument(data.details),
      user: inlineDocument(data.user),
      hasRemovedFromBlockedEmailList,
    };
  },

  async updateUserBlockedEmail(
    context: RequestContext,
    { userId, username }: UpdateUserBlockedEmailRequest,
    currentUserRoles: readonly Role[],
  ): Promise<UpdateUserResponse> {
    const [user, details] = await Promise.all([
      usersApi.getUser(context, userId),
      usersApi.getUserDetails(context, userId),
    ]);

    setByPath(
      user!,
      'attributes.providers.username_password.username',
      username,
    );

    const updatedUser = await usersApi.updateUser(
      context,
      { userId, user: user!, details },
      currentUserRoles,
    );

    return updatedUser;
  },

  async createAndUpdateUser(
    context: RequestContext,
    { details, user }: Omit<UpdateUserRequest, 'userId'>,
    currentUserRoles: readonly Role[],
  ): Promise<UpdateUserResponse> {
    const createdUser = await usersApi.createUser(context, {});

    return await usersApi.updateUser(
      context,
      {
        userId: createdUser.id!,
        details: {
          type: 'userdetails',
          attributes: { ...details?.attributes },
          ...details,
        },
        user: { ...createdUser, ...user },
      },
      currentUserRoles,
    );
  },

  async resetAuthy(
    context: RequestContext,
    user: Inlined<UserResource>,
    userId: string,
    currentUserRoles: readonly Role[],
  ): Promise<ResetAuthyResponse> {
    const url = apiURL`/admin/users/${userId}/bulkSave`;

    await using _lock = await acquireLock(getUserDataLockName(userId), {
      signal: context.signal,
      mode: 'exclusive',
    });

    const filteredUser = {
      id: user.id,
      type: user.type,
      attributes: createAllowedUserAttributesUpdate(
        currentUserRoles,
        user.attributes!,
      ),
    };

    // hack to reset authy 🤷‍♀️
    filteredUser.attributes.authyId = null;

    const outgoingDocument: UserBulkSaveRequest = {
      user: { data: filteredUser as any },
    };

    const response = await fetchWrapper(url.href, {
      ...context,
      method: 'PATCH',
      body: JSON.stringify(outgoingDocument),
    });

    const data: UserBulkSaveResponse = await response.json();

    return {
      userId,
      user: inlineDocument(data.user),
    };
  },

  async getUserDetails(
    context: RequestContext,
    userId: string,
  ): Promise<Inlined<UserDetails>> {
    const url = apiURL`/admin/users/${userId}/details`;

    await using _lock = await acquireLock(getUserDataLockName(userId), {
      signal: context.signal,
      mode: 'shared',
    });

    const response = await fetchWrapper(url.href, {
      ...context,
      method: 'GET',
    });

    const document: DataDocument<UserDetails> = await response.json();

    return inlineDocument(document);
  },

  async updatePassword(
    context: RequestContext,
    changePasswordParams: ChangePasswordConfig,
  ): Promise<void> {
    const url = apiURL`/users/registration/changePassword`;

    await fetchWrapper(url, {
      ...context,
      method: 'POST',
      body: JSON.stringify(changePasswordParams),
    });
  },

  async getValidRealms(
    context: RequestContext,
    didRetry = false,
  ): Promise<readonly string[]> {
    const url = apiURL`/users/validRealms`;

    try {
      const response = await fetchWrapper(url, context);
      const data = await response.json();
      return data.realms.toSorted();
    } catch (error) {
      if (!didRetry && error instanceof HttpError && error.status === 400) {
        return await usersApi.getValidRealms(context, true);
      }
      return [];
    }
  },

  async setupMfa(
    context: RequestContext,
    cellphone: string,
    countryCode: string,
  ): Promise<MfaSetup> {
    const url = apiURL`/admin/users/mfa/setup`;

    const response = await fetchWrapper(url, {
      ...context,
      method: 'POST',
      body: JSON.stringify({ cellphone, countryCode }),
    });

    const rawResponse = await response.text();

    const document = safeJsonParse(rawResponse);

    // It might not be supported in the current environment
    if (!isMfaSetupDataDocument(document)) {
      return {
        qrCode: null,
      };
    }

    return {
      qrCode: document.data.attributes.qrCode,
    };
  },

  async getMfaStatus(context: RequestContext): Promise<MfaStatus> {
    const url = apiURL`/admin/users/mfa/status`;

    await using _lock = await acquireLock(MFA_VERIFY_LOCK_NAME, {
      signal: context.signal,
    });

    const response = await fetchWrapper(url, context);
    const document: unknown = await response.json();

    if (!isMfaStatusDataDocument(document)) {
      throw new TypeError('Invalid response');
    }

    return document.data.attributes.status;
  },

  async verifyMfaCode(
    context: RequestContext,
    otp: string,
  ): Promise<verifyMfaCodeResponse> {
    const url = apiURL`/admin/users/mfa/verify`;

    await using _lock = await acquireLock(MFA_VERIFY_LOCK_NAME, {
      signal: context.signal,
    });

    try {
      await fetchWrapper(url, {
        ...context,
        method: 'POST',
        body: JSON.stringify({ otp }),
      });
      return { status: MfaVerifyStatus.Approved };
    } catch (error) {
      if (error instanceof ApiError && error.code === 'invalid.otp') {
        return { status: MfaVerifyStatus.InvalidOtp };
      }
      if (error instanceof ApiError && error.status === 429) {
        const retryAfter = error.meta?.retryAfter;
        return {
          status: MfaVerifyStatus.Suspended,
          retryAfter:
            typeof retryAfter === 'string' ? moment(retryAfter) : undefined,
        };
      }
      throw error;
    }
  },

  async getStudioConfig(context: RequestContext): Promise<StudioConfig> {
    const url = apiURL`/admin/users/studioConfig`;

    const response = await fetchWrapper(url, context);
    const json = await response.json();

    return json.data;
  },

  async getUmsUiConfig(
    context: RequestContext,
    { include = ['monetization'] }: { include?: 'monetization'[] } = {},
  ): Promise<Inlined<UmsUiConfig>> {
    const url = apiURL`/admin/users/umsUiConfig`;
    if (include.length > 0) {
      url.searchParams.set('include', include.join(','));
    }
    const response = await fetchWrapper(url, context);
    const document: DataDocument<UmsUiConfig> = await response.json();
    return inlineDocument(document);
  },

  async getProfiles(
    context: RequestContext,
    userId: string,
  ): Promise<readonly Inlined<UserProfile>[]> {
    const url = apiURL`/admin/users/${userId}/profiles`;
    const response = await fetchWrapper(url, context);
    const document: DataDocument<UserProfile[]> = await response.json();
    return inlineDocument(document);
  },

  async getDevices(
    context: RequestContext,
    userId: string,
  ): Promise<readonly Inlined<UserDevice>[]> {
    try {
      const url = apiURL`/admin/users/${userId}/devices`;
      const response = await fetchWrapper(url, context);
      const document: DataDocument<UserDevice[]> = await response.json();
      return inlineDocument(document);
    } catch (error) {
      // Api returns 404 means the device list is empty
      if (error instanceof HttpError && error.status === 404) {
        return [];
      }
      throw error;
    }
  },

  async getProfile(
    context: RequestContext,
    {
      userId,
      profileId,
    }: {
      userId: string;
      profileId: string;
    },
  ): Promise<Inlined<UserProfile> | null> {
    const profiles = await usersApi.getProfiles(context, userId);

    // Currently, there doesn't exist any getProfileByProfileId-endpoint so we have to
    // filter the profile
    return (
      profiles.find(
        currentUserProfile => currentUserProfile.id === profileId,
      ) ?? null
    );
  },

  async createProfile(
    context: RequestContext,
    userId: string,
    profile: Inlined<UserProfile>,
  ): Promise<Inlined<UserProfile>> {
    const url = apiURL`/admin/users/${userId}/bulkSave`;

    const { data: rawProfileData } = uninlineResource(profile);

    const userBulkSaveRequest: UserBulkSaveRequest = {
      newProfiles: [{ data: rawProfileData }],
    };

    const response = await fetchWrapper(url, {
      ...context,
      method: 'PATCH',
      body: JSON.stringify(userBulkSaveRequest),
    });

    const { createdProfileIds, profiles }: UserBulkSaveResponse =
      await response.json();

    const createdProfileId = createdProfileIds[0];

    // Currently, it doesnt exist any getProfileByProfileId-endpoint so we have to
    // filter the profile
    const createdProfile = profiles.data.find(
      currentProfile => currentProfile.id === createdProfileId,
    );

    if (createdProfile === undefined) {
      throw new Error('Something went wrong! Cannot find the created profile');
    }

    return inlineDocument({
      data: createdProfile,
    });
  },

  async updateProfile(
    context: RequestContext,
    userId: string,
    originalProfile: Inlined<UserProfile>,
    modifiedProfile: Inlined<UserProfile>,
  ): Promise<Inlined<UserProfile>> {
    const url = apiURL`/admin/users/${userId}/bulkSave`;

    if (
      originalProfile.id !== modifiedProfile.id ||
      originalProfile.type !== modifiedProfile.type
    ) {
      throw new Error(`Can't patch a profile with another profile`);
    }

    const profileId = originalProfile.id;

    const { data: rawChangedProfileData } = uninlineResource({
      id: profileId,
      type: 'profile',
      // Compute a diff of modified attributes, so we only attempt to update what has changed
      attributes: rebase(
        modifiedProfile.attributes!,
        originalProfile.attributes!,
        {},
      ),
    } as const);

    const userBulkSaveRequest: UserBulkSaveRequest = {
      updatedProfiles: [{ data: rawChangedProfileData }],
    };

    const response = await fetchWrapper(url, {
      ...context,
      method: 'PATCH',
      body: JSON.stringify(userBulkSaveRequest),
    });

    const { profiles }: UserBulkSaveResponse = await response.json();

    const updatedProfile = profiles.data.find(
      currentProfile => currentProfile.id === profileId,
    );

    if (updatedProfile === undefined) {
      throw new Error(
        `Something went wrong! Cannot find profile with id ${profileId}`,
      );
    }

    return inlineDocument({
      data: updatedProfile,
    });
  },

  async deleteProfile(
    context: RequestContext,
    {
      userId,
      profileId,
    }: {
      userId: string;
      profileId: string;
    },
  ): Promise<void> {
    const url = apiURL`/admin/users/${userId}/bulkSave`;

    const userBulkSaveRequest: UserBulkSaveRequest = {
      deletedProfiles: [profileId],
    };

    await fetchWrapper(url, {
      ...context,
      method: 'PATCH',
      body: JSON.stringify(userBulkSaveRequest),
    });
  },

  async getUserPartners(
    context: RequestContext,
    userId: string,
  ): Promise<readonly Inlined<Partner>[]> {
    try {
      const url = apiURL`/admin/users/${userId}/partners`;
      const response = await fetchWrapper(url, context);
      const document: DataDocument<Partner[]> = await response.json();
      return inlineDocument(document);
    } catch (error) {
      // Service returns 400 if user does not exist
      if (error instanceof HttpError && error.status === 400) {
        return [];
      }
      throw error;
    }
  },

  async getPrivilegedIPs(context: RequestContext): Promise<PrivilegedIP[]> {
    const url = apiURL`/admin/users/privilegedIP`;
    const response = await fetchWrapper(url, context);
    const document: { data: PrivilegedIP[] } = await response.json();
    return document.data;
  },

  async savePrivilegedIP(
    context: RequestContext,
    {
      ip,
      location,
    }: {
      ip: string;
      location: string;
    },
  ): Promise<void> {
    const url = apiURL`/admin/users/privilegedIP/${ip}`;
    url.searchParams.set('location', location);

    await fetchWrapper(url, { ...context, method: 'POST' });
  },

  async deletePrivilegedIP(context: RequestContext, ip: string): Promise<void> {
    const url = apiURL`/admin/users/privilegedIP/${ip}`;
    await fetchWrapper(url, { ...context, method: 'DELETE' });
  },

  async getGeoOverrides(context: RequestContext): Promise<GeoOverride[]> {
    const url = apiURL`/admin/users/geo/override`;

    const response = await fetchWrapper(url, context);

    const document = await response.json();

    return Object.values(document.data);
  },

  async saveGeoOverride(
    context: RequestContext,
    {
      ip,
      cc,
    }: {
      ip: string;
      cc: string;
    },
  ): Promise<void> {
    const url = apiURL`/admin/users/geo/override/${ip}`;
    url.searchParams.set('country', cc);

    await fetchWrapper(url, { ...context, method: 'POST' });
  },

  async deleteGeoOverride(context: RequestContext, ip: string): Promise<void> {
    const url = apiURL`/admin/users/geo/override/${ip}`;

    await fetchWrapper(url, { ...context, method: 'DELETE' });
  },

  async getBlockedEmails(
    context: RequestContext,
    {
      page = SEARCH_DEFAULT_PAGE,
      pageSize = SEARCH_DEFAULT_PAGE_SIZE,
      query,
    }: BlockedEmailSearchQuery,
  ): Promise<Paginated<Inlined<BlockedEmail>>> {
    const url = apiURL`/admin/usermessaging/email/blacklists`;

    if (query !== undefined && query !== '') {
      url.searchParams.set('filter[email]', query.toString());
    }

    url.searchParams.set('page[size]', pageSize.toString());
    url.searchParams.set('page[number]', (page + 1).toString());

    const response = await fetchWrapper(url, context);

    const document: DataDocument<
      BlockedEmail[],
      { totalPages: number; totalCount: number }
    > = await response.json();

    const results = inlineDocument(document);

    return {
      list: results,
      ...document.meta,
    };
  },

  async getBlockedEmail(
    context: RequestContext,
    userId: string,
  ): Promise<Inlined<BlockedEmail> | null> {
    const url = apiURL`/admin/usermessaging/email/blacklist/${userId}`;

    const response = await fetchWrapper(url, context);
    const document: DataDocument<BlockedEmail> = await response.json();
    return inlineDocument(document);
  },

  async removeBlockedEmail(
    context: RequestContext,
    userId: string,
  ): Promise<void> {
    const url = apiURL`/admin/usermessaging/email/blacklist/${userId}`;

    await fetchWrapper(url, { ...context, method: 'DELETE' });
  },

  async getUserCommunicationHistory(
    context: RequestContext,
    {
      userId,
      page = SEARCH_DEFAULT_PAGE,
      pageSize = SEARCH_DEFAULT_PAGE_SIZE,
    }: CommunicationHistoryQuery,
  ): Promise<Paginated<Inlined<CommunicationHistory>>> {
    const url = apiURL`/admin/usermessaging/communication/history/${userId}`;

    url.searchParams.set('page[size]', pageSize.toString());
    url.searchParams.set('page[number]', page.toString());

    const response = await fetchWrapper(url, context);
    const document: DataDocument<
      CommunicationHistory[],
      { total: number; totalPages: number }
    > = await response.json();

    const results = inlineDocument(document);

    return {
      list: results,
      ...document.meta,
    };
  },

  async getBrazeUserCommunicationHistory(
    context: RequestContext,
    { userId, email }: BrazeQuery,
  ): Promise<BrazeUser | undefined> {
    const url = 'https://rest.iad-01.braze.com/users/export/ids';

    const response = await fetchWrapper(url, {
      ...context,
      method: 'POST',
      mode: 'cors',
      credentials: 'omit',
      skipInterceptors: true,
      skipDeviceInfo: true,
      skipRetries: true,
      headers: {
        authorization: `Bearer ${process.env.BRAZE_TOKEN}`,
      },
      body: JSON.stringify({
        email_address: email,
        external_ids: [userId],
      }),
    });

    const data: BrazeResponse = await response.json();

    return data.users[0];
  },

  async getTemplatePreview(
    context: RequestContext,
    { templateName, templateVersion, realm, tokens }: TemplatePreviewQuery,
  ): Promise<EmailTemplatePreview> {
    const url = apiURL`/admin/usermessaging/email/preview/${templateName}/${templateVersion}`;

    const response = await fetchWrapper(url, {
      ...context,
      method: 'POST',
      body: JSON.stringify({ attributes: { realm, tokens } }),
    });

    return await response.json();
  },

  async getCustomAttributes(
    context: RequestContext,
    customAttributesId: string,
  ): Promise<unknown> {
    const url = apiURL`/users/me/customAttributes/${customAttributesId}`;

    await using _lock = await acquireLock(getUserDataLockName(context.userId), {
      signal: context.signal,
      mode: 'shared',
    });

    const response = await fetchWrapper(url, {
      ...context,
      method: 'GET',
    });

    const document: DataDocument<AnyResource> = await response.json();

    return document.data.attributes;
  },

  async setCustomAttributes(
    context: RequestContext,
    customAttributesId: string,
    customAttributes: unknown,
  ): Promise<void> {
    const url = apiURL`/users/me/customAttributes/${customAttributesId}`;

    await using _lock = await acquireLock(getUserDataLockName(context.userId), {
      signal: context.signal,
      mode: 'exclusive',
    });

    await fetchWrapper(url, {
      ...context,
      method: 'PUT',
      body: JSON.stringify({
        data: {
          type: 'userCustomAttributes',
          id: customAttributesId,
          attributes: customAttributes,
        },
      }),
    });
  },

  async getUserIdByUsername(
    context: RequestContext,
    username: string,
  ): Promise<string> {
    const url = apiURL`/admin/users/freeTextSearch`;

    const response = await fetchWrapper(url, {
      ...context,
      method: 'POST',
      body: JSON.stringify({
        freeText: username,
        pageSize: 1,
      }),
    });

    const document: {
      data: FreeTextSearchUser[];
      meta: { totalHits: number };
    } = await response.json();

    const [documentData] = document.data;
    if (documentData?.username !== username) {
      throw new Error(`Cannot find the username in the current subdivision`);
    }
    return documentData.userId;
  },
  async getUserDownloads(
    context: RequestContext,
    userId: string,
  ): Promise<Downloads> {
    const url = apiURL`/admin/downloads-manager/v1/downloads/user/${userId}`;

    const response = await fetchWrapper(url, {
      ...context,
      method: 'GET',
    });

    const document: Downloads = await response.json();

    return document;
  },
  async expireUserDownloads(
    context: RequestContext,
    userId: string,
  ): Promise<void> {
    const url = apiURL`/admin/downloads-manager/v1/downloads/user/${userId}`;

    await fetchWrapper(url, { ...context, method: 'DELETE' });
  },

  async expireUserDownload(
    context: RequestContext,
    { userId, editId, deviceId, profileId }: Download,
  ): Promise<void> {
    const url = apiURL`/admin/downloads-manager/v1/downloads`;
    url.searchParams.set('userId', userId);
    url.searchParams.set('editId', editId);
    url.searchParams.set('deviceId', deviceId);
    url.searchParams.set('profileId', profileId);
    await fetchWrapper(url, { ...context, method: 'DELETE' });
  },

  async defederateUser(
    context: RequestContext,
    { userId, gauthUserId, details }: DefederateUserPayload,
  ): Promise<void> {
    const url = apiURL`/admin/users/${userId}/defederateUser`;
    await fetchWrapper(url, {
      ...context,
      method: 'POST',
      body: JSON.stringify({
        gauthUserId,
        details,
      }),
    });
  },
};

export default usersApi;
