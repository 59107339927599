import type { Currency } from '@studio/utils/currencies';
import type { PublishingSite as PublishingSiteId } from '@studio/utils/experience';
import type { EnvironmentConfig } from '@studio/utils/fromEnvironment';

import type {
  Inlined,
  ResourceObject,
  ToManyRelationship,
  ToOneRelationship,
} from '../json-api';
import type { StudioConfig, UmsUiConfig } from '../users';

export type Site = ResourceObject<
  'site',
  {
    name: string;
    uat: boolean;
    lookupKeys: readonly string[];
    paywallRegex?: string;
    paywallRelativeUrl?: string;
    ageRestrictedContentRatingsFilter?: ContentRatingsFilter;
    preferredContentRatingSystem?: string;
    baseCapabilityDefinitionIds?: BaseCapabilityDefinitionIds;
  },
  {
    defaultLanguageTags: ToManyRelationship<LanguageTag>;
    publishingSite: ToOneRelationship<PublishingSite>;
    availableLanguages: ToManyRelationship<LanguageTag>;
    clientLanguages: ToManyRelationship<LanguageTag>;
    territoryGroups: ToManyRelationship<TerritoryGroup>;
    brand?: ToOneRelationship<Brand>;
    mainTerritory?: ToOneRelationship<Territory>;
    courtesySite?: ToOneRelationship<Site>;
    courtesySiteTerritoryGroup?: ToOneRelationship<TerritoryGroup>;
    ageRestrictedPublishingSite?: ToOneRelationship<PublishingSite>;
    contentRestrictionLevels?: ToManyRelationship<ContentRestrictionLevel>;
  }
>;

export interface ContentRatingsFilter {
  allowed: readonly ContentRatingsFilterSpecification[];
  disallowed: readonly ContentRatingsFilterSpecification[];
}

export interface ContentRatingsFilterSpecification {
  system: string;
  ratingCodes: readonly string[];
  descriptorCodes: readonly string[];
}

export interface BaseCapabilityDefinitionIds {
  anonymous: readonly string[];
  registered: readonly string[];
}

export interface Settings {
  studioConfig: StudioConfig;
  umsUiConfig: Inlined<UmsUiConfig>;
  environment: EnvironmentConfig | undefined;
}

export type ContentSubset = ResourceObject<
  'contentSubset',
  {
    name: string;
  }
>;

export interface PublishingSite {
  readonly type: 'publishingSite';
  readonly id?: PublishingSiteId | null;
  readonly attributes?: {
    readonly name: string;
  };
  readonly relationships?: {
    readonly sites: ToManyRelationship<Site>;
    /** @deprecated */
    readonly contentSubset: ToOneRelationship<ContentSubset>;
    readonly contentSubsets: ToManyRelationship<ContentSubset>;
    readonly productLine?: ToOneRelationship<ProductLineItem>;
  };
}

export enum TerritoryGroupKind {
  Market = 'market',
  Site = 'site',
  AdyenMerchant = 'adyen_merchant',
  CourtesySite = 'courtesy_site',
}

export enum TerritoryResourceType {
  Territory = 'territory',
  TerritoryGroup = 'territoryGroup',
  Region = 'region',
}

export type Territory = ResourceObject<
  TerritoryResourceType.Territory,
  {
    code: string;
    defaultCurrency: Currency;
    name: string;
    region?: string;
    tax?: number;
    taxDescription?: string;
    taxId?: string;
    taxType?: string;
  },
  {
    parentTerritory: ToOneRelationship<Territory>;
  }
>;

export type TerritoryGroup = ResourceObject<
  TerritoryResourceType.TerritoryGroup,
  {
    name: string;
    territoryGroupKind: TerritoryGroupKind;
  },
  { territories: ToManyRelationship<Territory> }
>;

export type LanguageTag = ResourceObject<
  'languageTag',
  {
    code: string;
    name: string;
    displayName: string;
  }
>;

export type Platform = ResourceObject<
  'platform',
  {
    name: string;
    description?: string;
    enabledForEntitlements?: boolean;
    type: PlatformType;
  }
>;

export enum PlatformType {
  TVSET = 'tvset',
  CONSOLE = 'console',
  MOBILE = 'mobile',
  WEB = 'web',
}

export type AppName = ResourceObject<
  'appName',
  {
    name: string;
  }
>;

export type AdStrategy = ResourceObject<
  'adStrategy',
  {
    name: string;
  }
>;

export type PlaybackSettings = ResourceObject<
  'playback',
  {
    defaultMaxConcurrentStreams: number;
  },
  {
    defaultAdStrategy: ToOneRelationship<AdStrategy>;
    adStrategies: ToManyRelationship<AdStrategy>;
  }
>;

export type Brand = ResourceObject<
  'brand',
  {
    name: string;
    enhancedSiteSelectionEnabled: boolean;
    selectableForPricePlan: boolean;
  },
  {
    courtesySite: ToOneRelationship<Site>;
    courtesySiteTerritoryGroup: ToOneRelationship<TerritoryGroup>;
  }
>;

export type ContentRestrictionLevel = ResourceObject<
  'contentRestrictionLevel',
  {
    name: string;
    description: string;
  },
  {
    sites?: ToManyRelationship<Site>;
  }
>;

export type ProductLineBrandItem = ResourceObject<
  'productLineBrandItem',
  {
    id: string;
    type: string;
  }
>;

export type ProductLine = ResourceObject<
  'productLine',
  {
    name: string;
  },
  {
    brands: ToManyRelationship<ProductLineBrandItem>;
  }
>;

export type ProductLineItem = ResourceObject<
  'productLineItem',
  {
    name: string | null | undefined;
  }
>;

export type ProductLineBrand = ResourceObject<
  'productLineBrand',
  {
    name: string;
    type: string;
  }
>;
