import { createContext, useContext } from 'react';

import type { AccessFn } from '@studio/api/access';

import type {
  Experience,
  ExperienceConfig,
  SecondaryExperienceKey,
  VerifiedExperience,
} from '../../utils/experience';

export interface ExperienceContextValue {
  readonly access: AccessFn;
  readonly experience: VerifiedExperience | null;
  readonly experienceConfig: ExperienceConfig;
  readonly enabledExperiences: readonly SecondaryExperienceKey[];
  readonly updatingExperience: boolean;
  readonly updateExperience: (experience: Experience) => void;
}

export const ExperienceContext = createContext<ExperienceContextValue | null>(
  null,
);
ExperienceContext.displayName = 'ExperienceContext';

export function useExperienceContext(): ExperienceContextValue | null {
  return useContext(ExperienceContext);
}
