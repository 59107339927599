import { memoizeWeakKey } from './memoize';

/** @deprecated Prefer {@link memoizeWeakKey} */
const memoizeWithWeakMap: <This, T extends object, R>(
  fn: (this: This, obj: T) => R,
) => {
  (this: This, obj: T): R;
  get cache(): WeakMap<T, R>;
} = memoizeWeakKey;

export default memoizeWithWeakMap;
