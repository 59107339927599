import type { Dispatch } from 'react';

import { isRole } from '@studio/api/access';
import { HeaderName, SENTRY_DSN } from '@studio/api/constants';
import customAttributesApi from '@studio/api/custom-attributes';
import {
  ApiError,
  HttpError,
  isInvalidSessionErrorCode,
} from '@studio/api/errors';
import identityApi, {
  extractPublishingSite,
  isPublishingSiteRole,
  type StudioIdentityTokenPayload,
} from '@studio/api/identity';
import settingsApi from '@studio/api/settings';
import usersApi, {
  type CurrentUserLegacy,
  type CurrentUserModern,
  UserType,
} from '@studio/api/users';
import {
  addRequestInterceptor,
  addResponseInterceptor,
} from '@studio/api/utils';
import type { SubdivisionStrategies } from '@studio/api/utils/subdivision';
import { createAbortError } from '@studio/utils/abortError';
import ensureError from '@studio/utils/ensureError';
import { delay } from '@studio/utils/promise';
import type { RequestContext } from '@studio/utils/requestContext';
import type { TaggedUnion } from '@studio/utils/types';

import type { ExperienceConfig } from '../../utils/experience';
import type { PermissionGroupName } from '../../utils/permission';
import { type SessionAction, SessionActionType } from './actions';
import { LoginMode, type SessionState, SessionStateStatus } from './reducer';
import {
  lastRealmStore,
  oktaAuthProviderStore,
  sonicAuthedStore,
} from './stores';
import { makeStateMachineEffects, waitUntilVisible } from './utils';

// Not actually always true ¯\_(ツ)_/¯
export function isPermissionGroupName(
  _name: string,
): _name is PermissionGroupName {
  return true;
}

function isApiRequest(request: Request) {
  return new URL(request.url).pathname.startsWith('/api');
}

function isProbablyInvalidSessionError(error: unknown): error is HttpError {
  return (
    (error instanceof HttpError && error.status === 401) ||
    (error instanceof ApiError && isInvalidSessionErrorCode(error.code))
  );
}

let currentDispatch: Dispatch<SessionAction> | null = null;

const removeRequestInterceptor = addRequestInterceptor(
  async function interceptRequest(request, loginMode) {
    if (isApiRequest(request) && loginMode === LoginMode.Modern) {
      try {
        const { token } = await identityApi.getToken(request.signal);
        request.headers.set(HeaderName.StudioIdentityToken, token);
      } catch (error) {
        // If this call failed with 401, we need to force a sign-out
        if (error instanceof HttpError && error.status === 401) {
          currentDispatch?.({ type: SessionActionType.Logout });
          throw createAbortError();
        }
        throw error;
      }
    }

    return request;
  },
);

const removeResponseInterceptor = addResponseInterceptor(
  async function interceptResponse(
    response,
    request,
    loginMode,
    didRetry = false,
  ) {
    if (!isApiRequest(request) || loginMode === null) {
      return response;
    }

    const error = await HttpError.fromRequestResponse(request, response);

    if (error != null) {
      if (didRetry || !isProbablyInvalidSessionError(error)) {
        throw error;
      }

      if (loginMode !== LoginMode.Modern) {
        // Force a sign out
        currentDispatch?.({ type: SessionActionType.Logout });
        throw createAbortError();
      }

      try {
        const { token } = await identityApi.getToken(request.signal);
        request.headers.set(HeaderName.StudioIdentityToken, token);
      } catch (tokenError) {
        // The refresh failed with 401, we know for sure the session is invalid
        if (tokenError instanceof HttpError && tokenError.status === 401) {
          // Force a sign out
          currentDispatch?.({ type: SessionActionType.Logout });
          throw createAbortError();
        }
        throw new SuppressedError(tokenError, error);
      }

      // token refresh was successful, retry the original request
      // eslint-disable-next-line no-restricted-globals
      const nextResponse = await fetch(request);
      return await interceptResponse(nextResponse, request, loginMode, true);
    }

    return response;
  },
);

if (import.meta.webpackHot != null) {
  import.meta.webpackHot.dispose(() => {
    removeRequestInterceptor();
    removeResponseInterceptor();
    currentDispatch = null;
  });
}

type LoginFlow = TaggedUnion<
  { mode: LoginMode },
  {
    [LoginMode.Legacy]: {
      user: CurrentUserLegacy;
      tokenPayload: StudioIdentityTokenPayload | null;
    };
    [LoginMode.Hybrid]: {
      user: CurrentUserLegacy;
      tokenPayload: StudioIdentityTokenPayload;
    };
    [LoginMode.Modern]: {
      user: CurrentUserModern;
      tokenPayload: StudioIdentityTokenPayload;
    };
  }
>;

async function restoreSession(context: RequestContext): Promise<LoginFlow> {
  const [userResult, tokenResult] = await Promise.allSettled([
    usersApi.getCurrentUser(context),
    identityApi.getToken(context.signal),
  ]);

  // There are multiple possible scenarios:
  // - Token + User -> hybrid flow
  // - Token + No user -> modern flow or broken hybrid flow
  // - No token + User -> legacy flow or broken hybrid flow
  // - No token + No user -> incorrect flow

  // Token available: either modern or hybrid flow
  if (tokenResult.status === 'fulfilled') {
    const tokenPayload = tokenResult.value;

    // Modern flow:
    // - user request may fail
    // - user must be anonymous
    if (tokenPayload.mode === 'modern') {
      if (userResult.status === 'fulfilled') {
        const user = userResult.value;

        if (!user.anonymous) {
          // Used by the Studio Okta registration, to allow dual tokens in legacy mode
          if (!user.authProviders.includes('studio_okta')) {
            return {
              mode: LoginMode.Legacy,
              tokenPayload,
              user,
            };
          }

          throw new Error('Access denied: User is not anonymous');
        }
      }

      return {
        mode: LoginMode.Modern,
        tokenPayload,
        user: {
          type: UserType.Modern,
          id: tokenPayload.sub,
          email: tokenPayload.email,
          username: tokenPayload.email,
          realm: tokenPayload.realm,
          roles: tokenPayload.roles.filter(isRole).sort(),
          legacyRoles: tokenPayload.legacyRoles ?? tokenPayload.roles,
          groups: tokenPayload.groups.filter(isPermissionGroupName).sort(),
          allowedPublishingSites: (
            tokenPayload.legacyRoles ?? tokenPayload.roles
          )
            .filter(isPublishingSiteRole)
            .map(extractPublishingSite),
          selectedPublishingSite: undefined,
        },
      };
    }

    // Hybrid flow:
    // - user request must succeed
    // - user must not be anonymous
    if (userResult.status === 'rejected') {
      const userError = userResult.reason;
      throw new Error('Access denied: User request failed', {
        cause: userError,
      });
    }

    const user = userResult.value;

    if (user.anonymous) {
      throw new Error('Access denied: User is anonymous');
    }

    return {
      mode: LoginMode.Hybrid,
      tokenPayload,
      user,
    };
  }

  // Only user available: either legacy or unsuccessful hybrid flow
  if (userResult.status === 'fulfilled') {
    const user = userResult.value;

    if (user.anonymous) {
      throw new Error('Access denied: User is anonymous');
    }

    const tokenError = tokenResult.reason;

    // Infer hybrid flow by checking the error
    // - HttpError 401 + "TOKEN_MISSING" -> legacy
    // - HttpError 401 + other codes (INVALID_SIGNATURE) -> hybrid
    // - HttpError 503 -> legacy (SI not available)
    // - any other error -> treat as legacy for now
    const hybridFlow =
      user.authProvider === 'studio_okta' ||
      (tokenError instanceof ApiError &&
        tokenError.status === 401 &&
        tokenError.code !== 'ACCESS_TOKEN_MISSING' &&
        tokenError.code !== 'REFRESH_TOKEN_MISSING');

    if (hybridFlow) {
      throw new Error('Access denied: Invalid token', {
        cause: tokenError,
      });
    }

    return {
      mode: LoginMode.Legacy,
      tokenPayload: null,
      user,
    };
  }

  // Neither user or token available: unsuccessful flow
  throw new AggregateError(
    [userResult.reason, tokenResult.reason],
    'Session restoration failed',
  );
}

// This saga describes side-effects for each state, e.g. making a request.
// Only one saga is running at time, and any state modification will cancel the running saga and pick a new one.
export const useSessionEffects = makeStateMachineEffects<
  SessionState,
  SessionAction
>({
  async [SessionStateStatus.LoggingIn](context, { username, password, realm }) {
    try {
      const authToken = await usersApi.resetAuthToken(context, { realm });

      const { mfaAction = null } = await usersApi.login(context, {
        authToken,
        username,
        password,
      });

      return {
        type: SessionActionType.LoginSucceeded,
        mfaAction,
      };
    } catch (error) {
      return {
        type: SessionActionType.LoginFailed,
        error: ensureError(error),
      };
    }
  },

  async [SessionStateStatus.SettingUpMfa](context, { cellphone, countryCode }) {
    try {
      const { qrCode } = await usersApi.setupMfa(
        context,
        cellphone,
        countryCode,
      );

      return {
        type: SessionActionType.SetupMfaSucceeded,
        qrCode,
      };
    } catch (error) {
      return {
        type: SessionActionType.SetupMfaFailed,
        error: ensureError(error),
      };
    }
  },

  async [SessionStateStatus.VerifyingMfa](context) {
    await delay(2000, context.signal);
    await waitUntilVisible(context.signal);

    try {
      const mfaStatus = await usersApi.getMfaStatus(context);

      return {
        type: SessionActionType.VerifyMfaSucceeded,
        mfaStatus,
      };
    } catch (error) {
      return {
        type: SessionActionType.VerifyMfaFailed,
        error: ensureError(error),
      };
    }
  },

  async [SessionStateStatus.RestoringSession](context) {
    try {
      const { mode, user, tokenPayload } = await restoreSession(context);

      context = { ...context, loginMode: mode };

      let experienceConfig: ExperienceConfig | null = null;
      let subdivisionStrategies: SubdivisionStrategies | null = null;

      if (mode === LoginMode.Modern) {
        // TODO: don't hardcode this
        experienceConfig = {
          max: {
            subdivisionTenant: ['beam'],
            subdivisionMarket: ['amer', 'latam', 'emea', 'apac'],
            publishingSite: ['max_global', 'fallback', 'beam_us'],
            commerceLine: ['3P1GD1'],
            productLine: ['c01c1c07-e7bf-4cee-be6b-87092a30d41c'],
          },
          discoveryplus: {
            subdivisionTenant: ['dplus'],
            subdivisionMarket: ['amer', 'emea'],
            publishingSite: ['discovery_plus'],
            commerceLine: ['2RRCI7'],
            productLine: ['87671121-ed22-4ecb-8158-b5c2bd2472c3'],
          },
          bleacherreport: {
            subdivisionTenant: ['br'],
            subdivisionMarket: ['amer'],
            publishingSite: [],
            commerceLine: ['4Q2HE2'],
            productLine: ['d646434d-bcf9-4e40-88fb-c5ca52d8ccf0'],
          },
          cnn: {
            subdivisionTenant: ['cnn'],
            subdivisionMarket: ['amer'],
            publishingSite: [],
            commerceLine: ['1BH5NN'],
            productLine: ['01224710-a55c-4c9f-a9b1-6e84139f1d1a'],
          },
        };

        // TODO: Don't hardcode these
        // prettier-ignore
        subdivisionStrategies = [
          // SI routes have "no" subdivision - use the legacy route
          { path: '/identity', strategy: null },

          // https://github.com/wbd-streaming/users/blob/main/.omd/users/now.yaml
          { path: '/users', strategy: 'tenant-market' },
          { path: '/admin/users', strategy: 'tenant-market' },

          // https://github.com/wbd-streaming/fpa-authn/blob/main/.omd/idp/now.yaml
          { path: '/idp/users', strategy: 'market' },

          // https://github.com/wbd-streaming/fpa-authn/blob/main/.omd/idp/now.yaml
          { path: '/idp/admin', strategy: 'market' },

          // https://github.com/wbd-streaming/legal/blob/main/.omd/ncis/now.yaml
          { path: '/admin/legal', strategy: 'tenant-market' },

          // https://github.com/wbd-streaming/capabilities/blob/main/.omd/capabilities/now.yaml
          { path: '/admin/capabilities/v2/usercapability', strategy: 'tenant-market' },
          { path: '/admin/capabilities/usercapability', strategy: 'tenant-market' },
          { path: '/admin/capabilities/userppventitlement', strategy: 'tenant-market' },

          // https://github.com/wbd-streaming/partner-subscriptions/blob/main/.omd/partner-subscriptions/now.yaml
          { path: '/admin/partner-subscriptions', strategy: 'tenant-market' },

          // https://github.com/wbd-streaming/personalized-offers/blob/main/.omd/personalized-offers/now.yaml
          { path: '/admin/offers', strategy: 'tenant-market' },

          // https://github.com/wbd-streaming/monetization/blob/main/.omd/monetization/now.yaml
          { path: '/admin/monetization/umsUiConfig', strategy: 'tenant-market' },
          { path: '/admin/monetization/subscriptions', strategy: 'tenant-market' },
          { path: '/admin/monetization/transactions', strategy: 'tenant-market' },
          { path: '/admin/monetization/affiliates', strategy: 'tenant-market' },
          { path: '/admin/monetization/capabilities', strategy: 'tenant-market' },
          { path: '/admin/monetization/paymentmethods', strategy: 'tenant-market' },
          { path: '/admin/monetization/audit', strategy: 'tenant-market' },
          { path: '/admin/monetization/localizableTexts', strategy: 'tenant-market' },
          { path: '/admin/monetization/test-subscriptions', strategy: 'tenant-market' },

          // Uncomment to reenable CORS for everything
          // { path: '/', strategy: 'global' },
        ];

        context = { ...context, subdivisionStrategies };
      }

      // TODO: move these out of the session state
      const [settings, preferences, permissionGroups] = await Promise.all([
        settingsApi.getSettings(context),
        customAttributesApi.getPreferences(context, mode),
        experienceConfig != null
          ? identityApi.getPermissionGroups(context)
          : null,
      ]);

      return {
        type: SessionActionType.RestoreSessionSucceeded,
        mode,
        user,
        tokenPayload,
        subdivisionStrategies,
        permissionGroups,
        experienceConfig,
        settings,
        preferences,
      };
    } catch (error) {
      return {
        type: SessionActionType.RestoreSessionFailed,
        error: ensureError(error),
      };
    }
  },

  async [SessionStateStatus.LoggedIn](context, { user, settings }, dispatch) {
    lastRealmStore.setState(user.realm);

    oktaAuthProviderStore.setState(
      user.type === UserType.Modern ||
        user.authProviders.includes('studio_okta'),
    );

    // If the `dplay.SONIC_AUTHED` key is not set in local storage
    // the player will make a token request, which in turn will cause
    // the studio to sign out the user.
    // Setting this on login is a hack to buy some time for the player team
    sonicAuthedStore.setState(true);

    currentDispatch = dispatch;

    context.signal.addEventListener(
      'abort',
      () => {
        currentDispatch = null;
      },
      { once: true },
    );

    if (SENTRY_DSN !== undefined) {
      // Dynamic import to exclude Sentry in main.chunk
      const Sentry = await import(
        /* webpackChunkName: "sentry" */ '@sentry/react'
      );

      // Enrich Sentry with user and realm information globally
      Sentry.setTags({
        realm: user.realm,
        stackName: settings.environment?.stack,
      });
      Sentry.setUser({
        username: user.username,
        roles: user.roles.join(', '),
        id: user.id,
      });
    }
  },

  async [SessionStateStatus.LoggedOut]() {
    if (SENTRY_DSN !== undefined) {
      // Dynamic import to exclude Sentry in main.chunk
      const Sentry = await import(
        /* webpackChunkName: "sentry" */ '@sentry/react'
      );

      // Sentry clean up
      Sentry.setTags({
        realm: undefined,
        stackName: undefined,
      });
      Sentry.setUser(null);
    }
  },

  async [SessionStateStatus.LoggingOut](context, { lastRealm }) {
    try {
      await Promise.all([
        identityApi.revokeToken(context),
        identityApi
          .revokeSonicToken(context)
          // TODO: remove this when SI is deployed to Disco prod
          // This should only fail if there's a network error (e.g. the endpoint is not available in this env yet)
          .catch(error => {
            return usersApi
              .logout(context, { realm: lastRealm })
              .catch(logoutError => {
                throw new SuppressedError(logoutError, error);
              });
          }),
      ]);
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return {
        type: SessionActionType.LogoutFinished,
      };
    }
  },

  async [SessionStateStatus.Redirecting](_context, { url }) {
    window.location.assign(url);
  },
});
