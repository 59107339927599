import { useMemo } from 'react';

import type { AccessFn } from '@studio/api/access';

import {
  useEnvironmentConfigSafely,
  usePermissionGroups,
  useUserRaw,
} from '../../components/SessionManager';
import type { PermissionGroupName } from '../permission';
import {
  type AccessContextWithoutExperience,
  createExperienceAuthorizationChecker,
  type ExperienceAuthorizationChecker,
} from './authorization';
import {
  createExperienceRoleResolver,
  type ExperienceRoleResolver,
} from './roles';

const EMPTY_ARRAY: readonly never[] = [];
const EMPTY_MAP: ReadonlyMap<never, never> = new Map<never, never>();

export function useAccessContextWithoutExperience(): AccessContextWithoutExperience {
  const envConfig = useEnvironmentConfigSafely();
  const user = useUserRaw();

  const env = envConfig?.type;
  const stackName = envConfig?.stack;
  const realm = user?.realm;

  return useMemo(() => ({ env, realm, stackName }), [env, realm, stackName]);
}

export function useExperienceRoleResolver(
  userLegacyRoleNames: readonly string[] | undefined,
  userPermissionGroupNames: readonly PermissionGroupName[] | undefined,
): ExperienceRoleResolver {
  const permissionGroups = usePermissionGroups();

  return useMemo(() => {
    return createExperienceRoleResolver(
      userLegacyRoleNames ?? EMPTY_ARRAY,
      userPermissionGroupNames ?? EMPTY_ARRAY,
      permissionGroups ?? EMPTY_MAP,
    );
  }, [permissionGroups, userLegacyRoleNames, userPermissionGroupNames]);
}

export function useExperienceAuthorizationChecker(
  access: AccessFn,
  experienceRoleResolver: ExperienceRoleResolver,
): ExperienceAuthorizationChecker {
  const accessContextWithoutExperience = useAccessContextWithoutExperience();

  return useMemo(() => {
    return createExperienceAuthorizationChecker(
      access,
      accessContextWithoutExperience,
      experienceRoleResolver,
    );
  }, [access, accessContextWithoutExperience, experienceRoleResolver]);
}
