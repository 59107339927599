import { isObjectOf, isShapeOf, isUnknown } from 'giltig';

import type { Branded } from './types';

export type StudioExperience =
  | 'max'
  | 'discoveryplus'
  | 'bleacherreport'
  | 'cnn'
  | Branded<string, 'UnknownStudioExperience'>;

export type SubdivisionTenant =
  | 'beam'
  | 'dplus'
  | 'br'
  | 'cnn'
  | Branded<string, 'UnknownSubdivisionTenant'>;

export type SubdivisionMarket =
  | 'amer'
  | 'latam'
  | 'emea'
  | 'apac'
  | Branded<string, 'UnknownSubdivisionMarket'>;

export type PublishingSite =
  | 'max_global'
  | 'fallback' // "Max Courtesy"
  | 'beam_us'
  | 'discovery_plus'
  | `dplus_${Lowercase<string>}` // Legacy D+ Publishing Sites
  | Branded<string, 'UnknownPublishingSite'>;

export type ProductLine =
  | 'c01c1c07-e7bf-4cee-be6b-87092a30d41c' // Max
  | '87671121-ed22-4ecb-8158-b5c2bd2472c3' // Discovery+
  | 'd646434d-bcf9-4e40-88fb-c5ca52d8ccf0' // Bleacher Report
  | '01224710-a55c-4c9f-a9b1-6e84139f1d1a' // CNN
  | Branded<string, 'UnknownProductLine'>;

export type CommerceLine =
  | '3P1GD1' // Max
  | '2RRCI7' // Discovery+
  | '4Q2HE2' // Bleacher Report
  | '1BH5NN' // CNN
  | Branded<string, 'UnknownCommerceLine'>;

export function isMaybeStudioExperience(
  value: unknown,
): value is StudioExperience {
  return typeof value === 'string' && value.length > 0;
}

export function isMaybeSubdivisionTenant(
  value: unknown,
): value is SubdivisionTenant {
  return typeof value === 'string' && value.length > 0;
}

export function isMaybeSubdivisionMarket(
  value: unknown,
): value is SubdivisionMarket {
  return typeof value === 'string' && value.length > 0;
}

export function isMaybePublishingSite(value: unknown): value is PublishingSite {
  return typeof value === 'string' && value.length > 0;
}

export function isMaybeCommerceLine(value: unknown): value is CommerceLine {
  return typeof value === 'string' && value.length > 0;
}

export function isMaybeProductLine(value: unknown): value is ProductLine {
  return typeof value === 'string' && value.length > 0;
}

type Satisfies<T, U extends T> = U;

export type SecondaryExperiences = Satisfies<
  Readonly<Record<SecondaryExperienceKey, string>>,
  Readonly<{
    subdivisionTenant: SubdivisionTenant;
    subdivisionMarket: SubdivisionMarket;
    publishingSite: PublishingSite;
    productLine: ProductLine;
    commerceLine: CommerceLine;
  }>
>;

export const SECONDARY_EXPERIENCE_KEYS = [
  'subdivisionTenant',
  'subdivisionMarket',
  'publishingSite',
  'commerceLine',
  'productLine',
] as const satisfies string[];

export type SecondaryExperienceKey = (typeof SECONDARY_EXPERIENCE_KEYS)[number];

export const EXPERIENCE_KEYS = [
  'studioExperience',
  ...SECONDARY_EXPERIENCE_KEYS,
] as const;

export type ExperienceKey = (typeof EXPERIENCE_KEYS)[number];

const EXPERIENCE_KEYS_LOOSE: readonly string[] = EXPERIENCE_KEYS;

export function isExperienceKey(value: string): value is ExperienceKey {
  return EXPERIENCE_KEYS_LOOSE.includes(value);
}

export const isObjectWithExperience = isShapeOf({
  experience: isObjectOf(isUnknown),
});
